import moment from "moment";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
} from "@/constants/billingInformations";

export default {
  type: "reseller-invoices",
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  code: null,
  excerpt: "",
  discounts: [],
  relationshipNames: ["recipient", "reseller"],
  recipient: {
    type: 'organizations',
    id: null,
  },
  reseller: {
    type: "resellers",
    id: null,
  },
  items: [],

  billing_entity_type: BILLING_ENTITY_TYPE_INDIVIDUAL,
  billing_company_name: "N/A",
  billing_firstname: "N/A",
  billing_lastname: "N/A",
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: "N/A",
  billing_zipcode: "N/A",
  billing_address: "N/A",
  billing_email: "N/A",
};
